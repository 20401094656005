.l-main-nav {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 1.125em;
    padding: 1.85em 0;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    &__item+.l-main-nav__item::before {
        content: "\25cf";
        font-size: 0.5em;
        color: white;
        margin-right: 0.35em;
        vertical-align: middle;
    }
    &__link {
        padding: 0.6em;
        border: 1px solid transparent;
        transition: 0.25s ease-out;
    }
    &__link:hover, &__link:focus {
        border-color: white;
    }
    // à ajouter à la page courante
    .current-page {
        border-color: white;
    }
}
