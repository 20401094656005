@media screen and (max-width: 1100px) {
    .place {
        &__inner {
            max-width: 95%;
        }
    }
}

@media screen and (max-width: 900px) {
    .place {
        &__description {
            max-width: 50%;
            margin-left: 1rem;
        }
    }
}

@media screen and (max-width: 850px) {
    .place {
        &__text {
            flex-direction: column;
            align-items: center;
        }
        &__infos {
            max-width: 80%;
            width: 80%;
            margin-right: 0;
        }
        &__time {
            text-align: center;
        }
        &__contact {
            text-align: center;
        }
        &__map-img {
            width: 100%;
            height: auto;
        }
        &__description {
            max-width: 80%;
            margin-left: 0;
            margin-top: 2rem;
        }
    }
}

@media screen and (max-width: 550px) {
    .place {
        &__infos {
            max-width: 95%;
            width: 95%;
        }
        &__description {
            max-width: 95%;
        }
    }
}

@media screen and (max-width: 450px) {
    .place {
        &__images {
            display: none;
        }
    }
}