.about {
    &__subtitle {
        @include subtitle-underlined($raspberry);
        text-align: left;
    }
    &__subtitle-press {
        @include subtitle($raspberry);
        text-align: center;
    }
    &__subtitle-document {
        @include subtitle-underlined-small($black);
    }

    &__objectives {
        &-item {
            display: flex;
            align-items: flex-start;
            font-size: 1.2rem;
            letter-spacing: 1px;
            line-height: 1.3;
            &::before {
                content: url('../images/bullet-par.svg');
                display: inline-block;
            }
        }
        &-item+&-item {
            margin-top: 0.75rem;
        }
    }
    &__objective {
        margin-left: 1rem;
    }
    &__objectives-svg {
        .objectives-svg {
            filter: url('#svg-colored-dropshadow');
        }
        .obj-tree {
            fill: $pink;
        }
        .obj-table {
            fill: $raspberry;
        }
    }

    &__orga {
        &-list {

        }
        &-figure {
            border: 4px solid $black;
            @include subtleShadow($shadow25);
        }
        &-infos {
            background-color: $black;
            color: white;
        }
        &-name {
            text-transform: uppercase;
            letter-spacing: 0.5px;
            font-weight: 600;
        }
        &-function {
            letter-spacing: 0.5px;
            display: block;
        }
    }

    &__numbers {
        &-item {
            text-align: center;
            font-size: 2.5rem;
            color: $raspberry;
            line-height: 1;
        }
        &-num {
            font-weight: 600;
        }
        &-svg {
            fill: $pink;
            filter: url('#svg-colored-dropshadow');
        }
    }

    &__document {
        &-link {
            letter-spacing: 1px;
            display: block;
            color: $raspberry;
            text-transform: uppercase;
            font-weight: 500;
            &::after {
                content: "a";
                font-family: "l-icons";
                text-transform: none;
                font-size: 0.8em;
                margin-left: 0.5em;
                display: inline-block;
                transform: rotate(90deg);
                transition: 0.2s ease-out;
                position: relative;
                top: 0.1em;
            }
            &:hover::after, &:focus::after {
                transform: translateY(0.25em) rotate(90deg);
            }
        }
        &-desc {
            line-height: 1.5;
            letter-spacing: 1px;
        }
    }
}
