@media screen and (max-width: 1100px) {
   .agenda {
       &__inner {
           max-width: 95%;
       }
   }
}

@media screen and (max-width: 700px) {
    .agenda {
        &__date {
            min-width: 150px;
        }
        &__time {
            padding: 2rem 1rem;
        }
        &__activities {
            padding: 2rem 1rem;
        }
        .activity {
            &__infos {
                margin-left: 1.25rem;
            }
        }
    }
 }

@media screen and (max-width: 450px) {
    .agenda {
        &__day {
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-start
        }
        &__time, &__activities {
            padding: 1rem 0.5rem;
        }
    }
}
 
