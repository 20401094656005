.news {
    &--home {
        background-color: $yellow;
    }
}


.individual-news {
    &__wrapper {
        background-color: $light-gray;
    }
    &__inner {
        background-color: white;
    }
    &__subtitle {
        @include subtitle($raspberry);
        text-align: center;
    }
    &__date {
        font-size: 2rem;
        color: $raspberry;
        letter-spacing: 1.5px;
    }
    &__text {
        font-size: 1.25rem;
        letter-spacing: 0.5px;
    }
}

.single-news {
    background-color: white;
    border: 4px solid $black;
    @include subtleShadow($shadow25);
    margin-bottom: 3.75rem;
    &__title {
        color: $raspberry;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.5rem;
        letter-spacing: 1px;
        text-align: center;
        margin-bottom: 0.1em;
    }
    &__date {
        color: $raspberry;
        font-size: 1.25rem;
        letter-spacing: 1px;
    }
    &__content {
        letter-spacing: 0.5px;
    }
}
