.place {
    &__images {
        border: 4px solid $black;
    }
    &__description {
        font-size: 1.125rem;
        line-height: 1.5;
    }

    &__time {
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    &__contact {
        font-size: 1rem;
        letter-spacing: 1px;
    }

    &__mail, &__phone {
        display: block;
        font-weight: 500;
        transition: 0.2s ease-out;
        &:hover, :focus {
            color: $pink;
        }
    }
    &__address {
        letter-spacing: 1px;
    }

}
