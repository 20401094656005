.program {
    &__activities {
        max-width: $max-width;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        align-items: flex-start;
    }
    &__activity {
        width: 50%;
        text-align: center;
        margin-bottom: 2rem;
        &--inner {
            text-align: left;
            margin-top: 1.5rem;
        }
    }
    &__artists {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 5rem;
    }
    &__expos {
        z-index: 10;
        padding: 2rem;
        .program__title {
            margin-bottom: 0.25rem;
        }
        &--inner {
            margin-bottom: 2rem;
        }
    }
    &__shows {
        width: calc(50% + 2.5rem);
        margin-top: 5rem;
        margin-left: -2.5rem;
        padding: 2rem 4.5rem;
    }
    &__work {
        //padding: 2rem;
    }
    &__various {
        padding: 2rem;
    }
    &__expo, &__show, &__event {
        margin-bottom: 1rem;
    }
}

.expo, .show, .event {
    margin-bottom: 1rem;
}
