.header {
    background-color: $raspberry;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &__main-title {
        margin-bottom: 2.5em;
    }

    // Réseaux sociaux

    .l-social-nav {
        order: -1;
        margin: 1em auto 0 auto;
        max-width: $max-width;
        width: $max-width;
        &__list {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
    }
}


// Navigation principale

.l-main-nav {
    max-width: $max-width;
    width: $max-width;
    margin-bottom: 2.5em;
    &__list {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }
    &--home {
        margin-bottom: 5em;
    }
}

.burger-menu {
    display: none;
}
