.agenda {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__inner {
        max-width: $max-width;
        margin: 0 auto 3rem;
        width: 100%;
    }
    &__day {
        width: 100%;
        display: flex;
        align-items: stretch;
    }
    &__date {
        min-width: 210px;
        &--day {
            width: 100%;
        }
    }
    &__time {
        padding: 2rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    &__activities {
        padding: 2rem;
        display: flex;
        flex-direction: column;
    }

    .activity {
        display: flex;
        &+.activity {
            margin-top: 2rem;
        }
        &__infos {
            margin-left: 3rem;
        }
        &__time {
            min-width: 120px;
        }
        &__address {
            margin-top: 0.2rem;
        }
    }
}
