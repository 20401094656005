@media screen and (max-width: 545px) {
    .main-title {
        font-size: 4rem;
        margin-top: 1.5rem;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 380px) {
    .main-title {
        font-size: 3rem;
    }
}