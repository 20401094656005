.sorting {
    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        max-width: $max-width;
        margin-bottom: 3rem;
    }
    &__filters {
        display: flex;
    }
    &__filter-type {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        &+.sorting__filter-type {
            margin-left: 4rem;
        }
    }
    &__label {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.2rem;
        margin-bottom: 0.5em;
        letter-spacing: 1px;
        &--multi {
            text-transform: none;
            font-size: 1rem;
            letter-spacing: 0.5px;
            text-align: center;
            margin-top: 0.5rem;
        }
    }
    &__select {
        padding: 0.5em 3em 0.5em 0.5em;
        border: 3px solid $black;
        font-weight: 600;
        font-size: 0.883em;
        background: transparent url('../images/select-arrow.svg') no-repeat;
        background-position: right;
        border-radius: 0;
        -moz-appearance: none;
        -webkit-appearance: none;
    }
}
