.useful {
    &__intro {
        margin-bottom: 10rem;
    }
    &__places {
        width: 100%;
        text-align: center;
    }
    &__places--inner {
        max-width: $max-width;
        margin: 0 auto;
        position: relative;
        top: -10rem;
    }
    &__map {
        max-width: $max-width;
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
    &__cta {
        margin-bottom: -7rem;
    }

    &__other {
        max-width: $max-width;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    &__block {
        padding: 3rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &--inner {
            max-width: 50%;
        }
        &--parking {
            .useful__block--inner {
                margin-right: 6rem;
            }
            .useful__block--svg {
                width: 40%;
            }
        }
        &--transport {
            .useful__block--inner {
                margin-left: 6rem;
                order: 2;
            }
            .useful__block--svg {
                width: 40%;
            }
        }
    }
}
