@media screen and (max-width: 1100px) {
    .partners {
        &__wrapper {
            max-width: 95%;
        }
        &__item {
            min-width: 300px;
            margin-top: 1rem;
        }
    }
}