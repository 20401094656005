.newsletter {
    .mc4wp-form {
        font-family: $sourcesans;
        .mc4wp-success, .mc4wp-error {
            font-size: 1.2rem;
            text-align: center;
            color: white;
        }
    }
    &__label {
        color: white;
        font-size: 1.2rem;
        letter-spacing: 1px;
    }
    &__input {
        font-size: 1rem;
        border: 2px solid $black;
        border-right: 0px;
    }
    &__button {
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500;
        background-color: $yellow;
        border: 2px solid $black;
        padding: 0.8em;
        display: inline-block;
        text-align: center;
        position: relative;
        overflow: hidden;
        color: $raspberry;
        transition: all 0.5s ease-in-out;
        @include subtleShadow($shadow25);
        &__text {
            position: relative;
        }
        &::before {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            background-color: white;
            height: 0;
            width: 0;
            opacity: 0;
            transform: translateX(-50%) translateY(-50%) rotate(45deg) translateZ(0);
            transition: all 0.35s ease-in-out;
        }
        &:hover, &:focus {
            color: $black;
        }
        &:hover::before, &:focus::before {
            width: 100%;
            height: 4000%;
            opacity: 1;
        }
    }
    &--footer {
        .newsletter {
            &__label {
                color: $raspberry;
                font-weight: 500;
            }
            &__input {
                border-right: 2px solid $black;
            }
            &__button {
                padding: 0.5rem;
                background-color: $raspberry;
                color: white;
                &:hover, &:focus {
                    color: $raspberry;
                }
            }
        }
    }
}
