// Playfair Display

@font-face {
    font-family: Playfair Display;
    font-weight: 600;
    font-style: normal;
    src: url("../fonts/playfair-display/PlayfairDisplay-Bold.otf");
}

// Source Sans Pro

@font-face {
    font-family: Source Sans Pro;
    font-weight: 400;
    font-style: normal;
    src: url("../fonts/source-sans-pro/SourceSansPro-Regular.otf");
}

@font-face {
    font-family: Source Sans Pro;
    font-weight: 400;
    font-style: italic;
    src: url("../fonts/source-sans-pro/SourceSansPro-It.otf");
}

@font-face {
    font-family: Source Sans Pro;
    font-weight: 500;
    font-style: normal;
    src: url("../fonts/source-sans-pro/SourceSansPro-Semibold.otf");
}

@font-face {
    font-family: Source Sans Pro;
    font-weight: 500;
    font-style: italic;
    src: url("../fonts/source-sans-pro/SourceSansPro-SemiboldIt.otf");
}

@font-face {
    font-family: Source Sans Pro;
    font-weight: 600;
    font-style: normal;
    src: url("../fonts/source-sans-pro/SourceSansPro-Bold.otf");
}

@font-face {
    font-family: Source Sans Pro;
    font-weight: 600;
    font-style: italic;
    src: url("../fonts/source-sans-pro/SourceSansPro-BoldIt.otf");
}

// Icons

@font-face {
  font-family: "l-icons";
  src:url("../fonts/l-icons/l-icons.eot");
  src:url("../fonts/l-icons/l-icons.eot?#iefix") format("embedded-opentype"),
    url("../fonts/l-icons/l-icons.woff") format("woff"),
    url("../fonts/l-icons/l-icons.ttf") format("truetype"),
    url("../fonts/l-icons/l-icons.svg#l-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
