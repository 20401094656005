@media screen and (max-width: 1100px) {
    .l-main-nav {
        max-width: 90%;
        width: 90%;
        &__link {
            padding: 0.5em;
            display: inline-block;
            margin: 0;
        }
    }
    .header {
        .l-social-nav {
            max-width: 90%;
            width: 90%;
        } 
    } 
}

@media screen and (max-width: 965px) {
    .l-main-nav {
        max-width: 95%;
        width: 95%;
        &__link {
            padding: 0.25em;
        }
    }  
    .header {
        .l-social-nav {
            max-width: 95%;
            width: 95%;
        } 
    }   
}


@media screen and (max-width: 800px) {
    .l-main-nav {
        padding: 1em 0;
        &__list {
            flex-wrap: wrap;
            justify-content: center;
        }
        &__item {
            margin: 0.75em;
        }
    }
    .header {
        &__main-title {
            margin-bottom: 1.5em;
            text-align: center;
            width: 150px;
            z-index: 99;
        }
    }   
    
}

@media screen and (max-width: 545px) {
    .l-main-nav {
        &__item {
            margin: 0.5em;
        }
        &__link {
            padding: 0.3em;
        }
    }
    .header {
        &__main-title {
            margin-bottom: 1em;
        }
    }
}

@media screen and (max-width: 450px) {
    .header {
        .l-social-nav {
            margin-top: 1.75rem;
        } 
        &__main-title {
            margin-top: 1rem;
        }
    } 
    .l-main-nav {
        position: absolute;
        top: 6rem;
        left: 0;
        width: 100%;
        max-width: 100%;
        padding: 0; 
        z-index: 1000;
        &__list {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
        &__item {
            padding: 0;
            margin: 0;
            border: none;
            width: 100%;
        }
        &__item+.l-main-nav__item::before {
            content: "";
            @include hidden;
        }
        &__link {
            display: inline-block;
            width: 100%;
            padding: 1em 0 1em 2rem;
        }
    }
    .burger-menu {
        display: block;
        fill: white;
        width: 2.5rem;
        height: 2.5rem;
        position: absolute;
        top: 2rem;
        left: 2rem;
        cursor: pointer;
    }
    /* toggle on burger menu click */
    .hide-nav {
        display: none;
    }
}
