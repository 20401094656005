.artist {
    &__taxonomies {
        font-size: 1.5rem;
        letter-spacing: 1px;
    }
    &__social-link {
        fill: $raspberry;
        .icon-svg {
            width: 3em;
        }
        .circle {
            stroke: transparent;
            stroke-width: 5px;
            fill: transparent;
            transform-origin: 50% 50%;
            transition: 0.2s ease-out;
        }
        &:hover, &:focus {
            .circle:hover, .circle:focus {
                stroke: $raspberry;
                transform: scale(1.4);
            }
        }
    }

    &__subtitle {
        @include subtitle($raspberry);
        &--white {
            @include subtitle(white);
        }
    }

    &__photo {
        border: 4px solid $black;
    }
    &__contact {
        font-size: 1rem;
        letter-spacing: 1px;
    }
    &__mail, &__website {
        font-weight: 500;
        transition: 0.2s ease-out;
        &:hover, :focus {
            color: $pink;
        }
    }

    &__description {
        font-size: 1.125rem;
        line-height: 1.5;
    }

    &__places-list {
        background-color: white;
        color: $raspberry;
        font-weight: 500;
        @include subtleShadow($shadow25);
    }

    &__places-item {
        &::before {
            content: url('../images/bullet-par.svg');
            display: inline-block;
            width: 0;
            transition: 0.2s ease-out;
            transform-origin: center;
        }
        &:hover {
            &::before {
                transform: scale(1.2);
            }
        }
    }

    &__places-link {
        margin-left: 1rem;
        transition: 0.2s ease-out;
        &:hover, &:focus {
            color: $pink;
        }
    }

    &__line--full {
        background: linear-gradient(to right, $pink, $peach);
        transform: skewY(4deg);
    }
    &__inner-line {
        transform: skewY(-4deg);
    }
    &__img-item {
        border: 2px solid $black;
        @include subtleShadow($shadow25);
    }
}
