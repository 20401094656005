.artists {
    &__inner {
        text-align: center;
    }

    &__list {
        font-size: 0;
        max-width: $max-width;
        width: 100%;
        margin: 0 auto;
        // translate : see components artists (skew)
        list-style: none;
        text-align: left;
    }
    &__list-program {
        margin-left: 2.5%;
    }

    &__item {
        display: inline-block;
        margin: 0 0 2rem 2.5%;
        width: 22.5%;
        position: relative;
        background-color: $black;
    }
}

.archive-artists, .artists-program {
    width: 100%;
    .artists__list {
        position: relative;
        top: 5rem;
        margin-bottom: -2rem;
    }
    margin-bottom: 5rem;
}
