.l-program-nav {
    background-color: white;
    color: $raspberry;
    width: 100%;
    padding-top: 2rem;
    &__list {
        max-width: 800px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }
    &__item {
        position: relative;
        text-transform: uppercase;
        font-weight: 500;
        &::after {
            content: "";
            width: 0;
            height: 2px;
            background-color: $raspberry;
            display: inline-block;
            position: absolute;
            bottom: -0.2em;
            left: 0;
            transition: 0.3s ease-out;
        }
        &:hover::after, &:focus::after {
            width: 100%;
        }
    }
}
