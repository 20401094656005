.program__expos, .expos {
    border: 4px solid $raspberry;
    background-color: white;
    @include subtleShadow($raspberryShadow)
    .program__subtitle {
        color: $raspberry;
        transition: 0.2s ease-out;
        line-height: 1.1;
        &:hover, &:focus {
            color: $pink;
        }
    }
    &--inner {
        column-count: 2;
        column-width: 50%;
        column-fill: balance;
    }
}
.expos__hours {
    letter-spacing: 0.5px;
    &--archives {
        font-size: 1.25rem;
        letter-spacing: 1px;
    }
}
.program__expo, .expo {
    page-break-inside: avoid;
    margin-bottom: 1.5rem;
    .icon-svg {
        fill: $raspberry;
        &--website {
            width: 1.2rem;
            .arrow {
                transition: 0.2s ease-out;
            }
            &:hover, &:focus {
                .arrow {
                    transform: translate(2px, -2px);
                }
            }
        }
        &--facebook {
            width: 1.5rem;
            .circle {
                stroke: transparent;
                stroke-width: 5px;
                fill: transparent;
                transform-origin: 50% 50%;
                transition: 0.2s ease-out;
            }
            &:hover, &:focus {
                .circle {
                    stroke: $raspberry;
                    transform: scale(1.4);
                }
            }
        }
    }
}
.expo {
    &__type {
        display: inline-block;
        font-style: italic;
        font-size: 1rem;
    }
    &__social {
        display: inline-block;
        vertical-align: middle;
    }
    &__artists-title {
        font-weight: 500;
        margin-top: 0.75rem;
    }
    &__list {
        &--expo {
            margin-top: 0.5rem;
        }
        &--artist {
            .expo__item {
                &::before {
                    content: "\25CF";
                    display: inline-block;
                    width: 0;
                    color: $raspberry
                }
            }
        }
    }
    &__item {
        margin-bottom: 0.25rem;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        &--content {
            margin-left: 1rem;
        }
        &::before {
            content: url('../images/bullet-par.svg');
            display: inline-block;
            width: 0;
        }
    }
    &__artist {
        transition: 0.2s ease-out;
        &:hover, &:focus {
            color: $pink;
        }
    }
}
