// object

.footer {
    padding: 3.75rem 0 1rem;
    background-color: white;
    &__inner {
        max-width: $max-width;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }
    &__line {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 2rem;
        &--1 {
            .footer__column {
                width: 30%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }
        &--3 {
            justify-content: flex-start;
        }
    }
    &__title {
        display: inline-block;
        margin-bottom: 0.8rem;
    }
    &__twitter {
        width: 30%;

    }
    &__partenaires {
        width: 65%;
    }

    .partenaires {
        &__list {
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
        &__item {
            margin-right: 5%;
            min-width: 75px;
            text-align: center;
        }
    }

    &__logo {
        display: flex;
        align-items: flex-end;
    }
    &__svg {
        width: 75px;
    }
    &__sl {
        margin-left: 0.5em;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__social-nav {
        width: 100%;
        margin-top: 1rem;
    }

    &__nav {
        .footer__list {
            max-width: 75%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-top: 1em;
        }
    }
    &__credits {
        margin-top: 1rem;
        width: 100%;
        text-align: right;
    }
}
