.newsletter {
    .mc4wp-form {
        width: 80%;
        margin-top: -0.5rem;

        .mc4wp-form-fields {
            text-align: center;
        }
    }
    &__label {
        display: block;
        margin-bottom: 0.5rem;
        margin-right: 0;
    }
    &__button {
        margin-left: 0;
        cursor: pointer;
    }
    &__input {
        width: 40%;
        padding: 0.8rem;
    }
    &--footer {
        .mc4wp-form {
            width: 100%;
        }
        .newsletter {
            &__input {
                width: 100%;
                padding: 0.5rem;
            }
            &__button {
                display: block;
                margin-top: 0.25rem;
            }
        }
    }
}
