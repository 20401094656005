.about {
    &__anchor {
        padding-top: 1rem;
        display: block;
    }
    &__objectives, &__orga, &__numbers, &__anchor, &__press {
        max-width: $max-width;
        margin: 0 auto;
    }

    &__objectives {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5rem 0;
        &-list {
            margin-top: 1rem;
        }
    }
    &__objectives-wrapper {
        max-width: 60%;
        margin-left: 3rem;
    }

    &__objectives-svg {
        order: -1;
        max-width: 40%;
        svg {
            max-width: 400px;
            width: 100%;
        }
    }

    &__orga {
        margin-top: -12rem;
        padding-bottom: 3rem;
        position: relative;
        top: 12rem;
        z-index: 100;
        &-list {
            list-style: none;
            text-align: left;
            margin: 1rem auto 0 -2.5%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        &-item {
            display: inline-block;
            margin: 0 0 1rem 2.5%;
            width: 22.5%;
            position: relative;
        }
        &-figure {
            height: 100%;
            max-height: 238px;
            overflow: hidden;
        }
        &-img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &-infos {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            min-height: 30%;
            padding: 1em;
        }
    }
    &__numbers {
        padding-top: 5rem;
        &-list {
            margin: 2rem auto 0;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            width: 90%;
        }
        &-num {
            display: block;
        }
        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &-svg {
            width: 70%;
            margin-bottom: 1.5rem;
            svg {
                width: 100%;
            }
        }
    }

    &__press {
        padding: 5rem 0;
    }

    &__subtitle-press {
        margin-bottom: 3rem;
    }
    &__subtitle-document {
        margin-bottom: 0.5rem;
    }
    &__documents {
        margin-top: 2rem;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    &__document {
        width: 50%;
        margin-bottom: 5rem;
        &-link {
            margin-top: 1rem;
        }
        &-desc {
            width: 90%;
        }
    }
}
