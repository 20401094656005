.footer {
    background-color: white;

    &__title {
        color: $raspberry;
        font-weight: 500;
        font-size: 1.25rem;
    }
    &__sl {
        font-family: $playfair;
        color: $raspberry;
        font-size: 1.5rem;
    }
    .svg {
        &__circle {
            fill: $orange;
        }
        &__rectangle {
            fill: $yellow;
        }
        &__para {
            fill: $raspberry;
        }
    }
    .contact {
        &__name {
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: 0.5px;
            font-size: 1.125rem;
            margin-bottom: 0.25em;
        }
        &__phone {

        }
        &__address {
            margin: 0.25em 0;
        }
        &__mail {
            font-weight: 500;
            font-style: italic;
            transition: 0.2s ease-in-out;
            &:hover, &:focus {
                color: $raspberry;
            }
        }
    }
    &__line--3 {
        font-style: italic;
        .footer__link {
            font-weight: 500;
            transition: 0.2s ease-in-out;
            &:hover, &:focus {
                color: $raspberry;
            }
        }
    }
    &__nav {
        border-top: 4px solid $raspberry;
        color: $raspberry;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.5px;
    }
    &__item {
        position: relative;
        &::after {
            content: "";
            width: 0;
            height: 2px;
            background-color: $raspberry;
            display: inline-block;
            position: absolute;
            bottom: -0.2em;
            left: 0;
            transition: 0.2s ease-out;
        }
        &:hover::after, &:focus::after {
            width: 100%;
        }
    }
    &__credits {
        font-size: 0.889rem;
    }
    .partenaires {
        &__item {
            font-weight: 600;
            font-family: $playfair;
        }
    }
}
