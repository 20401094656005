.arrow-link {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.5px;
    display: inline-block;
    margin-top: 1rem;
    &::after {
        content: "a";
        font-family: "l-icons";
        text-transform: none;
        font-size: 0.8em;
        margin-left: 0.5em;
        display: inline-block;
        transition: 0.2s ease-out;
        position: relative;
        top: 0.1em;
    }
    &:hover::after, &:focus::after {
        transform: translate(0.8em);
    }
    &--raspberry {
        color: $raspberry;
    }
    &--pink {
        color: $pink;
    }
    &--orange {
        color: $orange;
    }

    &--artists {
        margin-top: 3rem;
    }
}

.cta-archive {
    font-size: 1.25rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    background-color: $raspberry;
    border: 4px solid $black;
    padding: 0.8em;
    display: inline-block;
    text-align: center;
    position: relative;
    overflow: hidden;
    color: white;
    transition: all 0.5s ease-in-out;
    @include subtleShadow($shadow25);
    &__text {
        position: relative;
    }
    &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        background-color: white;
        height: 0;
        width: 0;
        opacity: 0;
        transform: translateX(-50%) translateY(-50%) rotate(45deg) translateZ(0);
        transition: all 0.35s ease-in-out;
    }
    &:hover, &:focus {
        color: $raspberry;
    }
    &:hover::before, &:focus::before {
        width: 100%;
        height: 4000%;
        opacity: 1;
    }
    &--white {
        color: black;
        background-color: white;
        &::before {
            background-color: black;
        }
        &:hover, &:focus {
            color: white;
        }
    }
    &--white-yellow {
        color: black;
        background-color: white;
        &::before {
            background-color: $yellow;
        }
        &:hover, &:focus {
            color: black;
        }
    }
}

.link-back {
    font-size: 1.125rem;
    letter-spacing: 1px;
    display: block;
    max-width: $max-width;
    margin: 0 auto;
    padding-top: 1rem;
    &--bottom {
        padding-top: 0;
        margin-top: 3rem;
    }
    &--artists {
        margin-top: 8rem;
    }
    &::before {
        content: "a";
        font-family: "l-icons";
        text-transform: none;
        font-size: 0.8em;
        margin-right: 0.5em;
        display: inline-block;
        transform: rotate(180deg);
        transition: 0.2s ease-out;
        position: relative;
        top: 0.1em;
    }
    &:hover::before, &:focus::before {
        transform: translate(-0.8em) rotate(180deg);
    }
}

.anchor-link {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    display: block;
    max-width: $max-width;
    margin: 0 auto;
    color: $raspberry;
    &::after {
        content: "a";
        font-family: "l-icons";
        text-transform: none;
        font-size: 0.8em;
        margin-left: 0.5em;
        display: inline-block;
        transform: rotate(90deg);
        transition: 0.2s ease-out;
        position: relative;
        top: 0.1em;
    }
    &:hover::after, &:focus::after {
        transform: translateY(0.25em) rotate(90deg);
    }
}
