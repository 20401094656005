.useful {
    &__subtitle {
        @include subtitle($raspberry);
        text-align: left;
    }

    &__subtitle-underlined {
        @include subtitle-underlined($raspberry);
    }

    &__places {
        @include bg-gradient(right, $darkberry, $purple);
    }

    &__map {
        @include subtleShadow($shadow25);
    }

    &__places-list {
        color: white;
        text-transform: uppercase;
        letter-spacing: 1px;
        column-count: 3;
        font-size: 1.125rem;
        line-height: 1.3;
        list-style-type: none;
        text-align: left;
    }
    &__places-item {
        margin-bottom: 0.5em;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }
    &__places-number {
        font-weight: 600;
        min-width: 29px;
        text-align: right;
        padding: 0.2em;
    }
    &__places-name {
        margin-left: 1rem;
        transition: 0.2s ease-out;
    }
    &__places-link {
        display: inline-block;
        padding: 0.2em;
        transition: 0.3s ease-out;
        background-color: transparent;
        &:hover, &:focus {
            color: $purple;
            background-color: white;
        }
    }


    &__block {
        &--parking {

        }
        &--transport {

        }
    }
    &__svg {
        filter: url("#svg-colored-dropshadow");
        .bus {
            fill: $raspberry;
        }
        .tree {
            fill: $pink;
        }
        .parking-sign {
            fill: $yellow;
        }
        .car {
            fill: $raspberry;
        }
    }
    &__wysiwyg {
        line-height: 1.5;
        letter-spacing: 1px;
    }
}
