@media screen and (max-width: 1100px) {
    .banner {
        max-width: 90%;
        &__inner {
            padding: 2rem;
        }
    }
    .instagram {
        .sbi {
            max-width: 95%;
        }
    }
    .newsletter {
        &--home {
            .mc4wp-form {
                width: 90%;
            }
            .newsletter {
                &__input {
                    width: 50%;
                }
            }
        } 
    }
}

@media screen and (max-width: 965px) {
    .banner {
        max-width: 95%;
    }
}

@media screen and (max-width: 935px) {
    .home-title {
        margin-bottom: 2rem;
    }
    .instagram {
        .sbi {
            &_type_image {
                margin: 0 0 1.5rem 2.5%;
            }
        }
    }
}


@media screen and (max-width: 890px) {
    .banner {
        &__inner {
            padding: 1rem;
        }
        &__title {
            font-size: 3.25rem;
        }
        &__content {
            font-size: 1.75rem;
        }
    }
    .intro {
        .home-title {
            font-size: 1.5rem;
        }
        &--home {
            padding: 1.5rem 2.5rem;
            font-size: 1rem;
        }
    }
    .newsletter {
        &--home {
            .home-title {
                margin-bottom: 1.5rem;
            }
        }
        
    }
}

@media screen and (max-width: 850px) {
    .intro {
        max-width: 95%;
    }
}

@media screen and (max-width: 800px) {
    .banner {
        height: 250px;
        top: -2.5em;
    }
}

@media screen and (max-width: 700px) {
    .banner {
        &__inner {
            max-width: 70%;
        }
    }
    .instagram {
        .sbi {
            &_type_image {
                margin: 0 0 1rem 2.5%;
            }
        }
    }
    .newsletter {
        &--home {
            .newsletter {
                &__input {
                    width: 60%;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .banner {
        justify-content: center;
        &__inner {
            padding: 2rem;
            max-width: 90%;
        }
    }
}

@media screen and (max-width: 545px) {
    .banner {
        text-align: center;
        &__title {
            font-size: 2.5rem;
        }
        &__content {
            font-size: 1.5rem;
        }
    }
    .intro {
        .home-title {
            margin-bottom: 0.5rem;
        }
        &--home {
            padding: 1.5rem;
        }
    }
    .home-title {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }
    .home-section {
        padding: 2rem 0;
    }
}


@media screen and (max-width: 450px) {
    .banner {
        height: 250px;
        top: -6em;
        background-image: none;
        @include bg-gradient(right, $pink, $peach);
        width: 100%;
        max-width: 100%;
        transform: skewY(20deg);
        margin-bottom: -6em;
        &__inner {
            transform: skewY(-20deg);
            padding: 1rem;
        }
        &__title {
            display: none;
        }
        &__content {
            position: relative;
            top: -0.5em;
        }
    }
    .intro {
        .home-title {
            line-height: 1.2;
        }
        &--home {
            position: relative;
            top: -4em;
            margin-bottom: -4em;
        }
    }
    .instagram {
        .sbi {
            &_type_image {
                width: 45% !important;
                min-width: 45%;
                max-height: 200px;
            }
        }
    }
    .newsletter {
        &--home {
            .mc4wp-form {
                width: 100%;
            }
        } 
    }
}

@media screen and (max-width: 350px) {
    .intro {
        &--home {
            width: 98%;
            max-width: 98%;
            padding: 0.75em;
        }
    }
    .instagram {
        width: 98%;
        max-width: 98%;
        .sbi {
            &_type_image {
                width: 90% !important;
                min-width: 90%;
                max-height: 250px;
            }
        }
    }
    .newsletter {
        &--home {
            .newsletter {
                &__input {
                    width: 50%;
                }
            }
        }
    }
}