@media screen and (max-width: 1100px) {
    .link-back {
        max-width: 95%;
    }
}


@media screen and (max-width: 600px) {
    .cta-archive {
        font-size: 1rem;
        border-width: 3px;
    }
}
