.program {
    &__title {
        font-size: 1.8rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 600;
        margin-bottom: 2rem;
        text-align: center;
        &--artists {
            color: white;
        }
    }
    &__subtitle {
        font-size: 1.25rem;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-weight: 600;
        margin-bottom: 0.2rem;
    }

    &__place {
        letter-spacing: 0.5px;
        &--name, &--address {
            display: inline;
        }
    }
}
