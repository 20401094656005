.intro {
    font-size: 1.25rem;
    line-height: 1.7;
    &__content {
        text-align: left;
    }
    &--home {
        background-color: white;
        border: 4px solid $raspberry;
        @include subtleShadow($raspberryShadow);
    }
}
