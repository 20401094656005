.program__work, .work {
    &--inner {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
    }
    .work__link {
        display: inline-block;
        width: 200px;
        height: 200px;
        overflow: hidden;
        border-radius: 50%;
        border: 4px solid $yellow;
        @include subtleShadow($yellowShadow);
        margin: 0 1rem 1rem;
    }
    .work__img {
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}
