.artist {
    &__taxonomies {
        text-align: center;
        max-width: $max-width;
        margin: 0 auto 1rem;
    }
    &__social {
        text-align: center;
        max-width: $max-width;
        margin: 0 auto 2rem;
    }
    &__subtitle {
        display: inline-block;
        margin-bottom: 1rem;
    }

    &__content {
        margin-top: 3rem;
        display: flex;
        flex-wrap: wrap;
    }
    &__line {
        display: flex;
        max-width: $max-width;
        margin: 0 auto;
        &--full {
            max-width: 100%;
            width: 100%;
            margin-top: 7.5rem;
            padding-bottom: 2rem;
        }
    }
    &__inner-line {
        max-width: $max-width;
        width: $max-width;
        margin: 0 auto;
        display: flex;
    }
    &__block--filler {
        width: 37.5%;
        margin-right: 4rem;
    }

    //** order, widths & margins **//
    &__picture {
        order: 1;
        margin-right: 4rem;
        width: 37.5%;
    }
    &__contact {
        margin-top: 0.5rem;
        display: flex;
        flex-direction: column;
    }
    &__contact-line {
        margin-bottom: 0.125em;
    }

    &__description {
        order: 2;
        width: 62.5%;
        padding-bottom: 10rem;
    }
    &__gallery {
        order: 2;
        width: 62.5%;
        margin-top: -10rem;
    }
    &__places {
        order: 1;
        margin-right: 4rem;
        width: 37.5%;
        padding: 3rem 0;
    }
    &__places-list {
        padding: 1.5rem;
    }

    &__places-item {
        &:not(:last-of-type) {
            margin-bottom: 1em;
        }
    }

    //**photo**//
    &__photo {
        width: 100%;
    }

    //** gallery **/
    &__img-list {
        display: flex;
        flex-wrap: wrap;
    }
    &__img-item {
        width: calc(50% - 0.4rem);
        height: 250px;
        margin-bottom: 0.8rem;
        overflow: hidden;
    }
    &__img-item:nth-of-type(even) {
        margin-left: 0.8rem;
    }
    &__work {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}
