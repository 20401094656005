.news {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__wrapper {
        max-width: $max-width;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
    }
}

.individual-news {
    &__wrapper {
        padding-bottom: 3rem;
    }
    &__inner {
        max-width: $max-width;
        margin: 0 auto;
        margin-top: 5rem;
        padding: 0 6rem 2rem;
    }
    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__title {
        position: relative;
        top: -3rem;
    }
    &__subtitle {
        max-width: $max-width;
        margin: 0 auto 2rem;
    }
    &__date {
        position: relative;
        top: -4rem;
    }
    &__img-wrapper {
        width: 100%;
        max-height: 350px;
        overflow: hidden;
        margin-bottom: 3rem;
        @include subtleShadow($shadow25);
    }
    &__img {
        width: 100%;
        display: block;
    }
    &__last {
        padding-top: 3rem;
    }
}

.single-news {
    width: 46%;

    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem;
    }
    &__title {
        margin-bottom: -0.3rem;
    }
    &__date {
        margin-bottom: 1rem;
    }
    &__figure {
        width: 100%;
        max-height: 200px;
        overflow: hidden;
        margin-bottom: 1rem;
    }
    &__img {
        width: 100%;
    }
    &__link {
        margin-top: 1rem;
    }
}
