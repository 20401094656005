@media screen and (max-width: 1100px) {
    .artist {
        &__line, &__inner-line {
            max-width: 95%;
        }
        &__line--full {
            max-width: 100%;
        }
    }
}

@media screen and (max-width: 850px) {
    .artist {
        &__description {
            padding-bottom: 5rem;
        }
        &__places, &__picture, &__block--filler {
            margin-right: 2rem;
        }
    }
}

@media screen and (max-width: 700px) {
    .artist {
        &__social {
            margin-bottom: 1rem;
        }
        &__content {
            margin-top: 0;
        }
        &__line, &__inner-line {
            flex-direction: column;
            align-items: center;
        }
        &__picture, &__places, &__block--filler {
            margin-right: 0;
            width: 80%;
            padding-bottom: 1rem;
        }
        &__contact {
            margin-top: 0;
        }
        &__description, &__gallery {
            margin-top: 1rem;
            width: 80%;
            padding-bottom: 0;
        }
        &__line--full {
            margin-top: 3rem;
        }
        &__gallery {
            .artist__subtitle {
                color: white;
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .artist {
        &__picture, &__description, &__places, &__gallery {
            width: 95%;
        }
    }
}