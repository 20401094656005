@mixin hidden {
    font-size: 0;
    text-indent: -9999px;
    line-height: 0;
    padding: 0;
    margin: 0;
    border: 0;
    letter-spacing: 0;
}

@mixin subtleShadow($color) {
    box-shadow: 2px 2px 20px $color;
}

@mixin bg-gradient($direction, $color1, $color2) {
    background: linear-gradient(to $direction, $color1, $color2);
}

@mixin subtitle($color) {
    font-size: 1.25rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    line-height: 1.2;
    color: $color;
}

@mixin subtitle-underlined($color) {
    font-size: 2rem;
    letter-spacing: 1px;
    font-weight: 600;
    line-height: 1.2;
    color: $color;
    display: inline-block;
    &::after {
        content: "";
        display: inline-block;
        background-color: $color;
        height: 2px;
        width: 30%;
        position: relative;
        top: -0.5rem;
    }
}

@mixin subtitle-underlined-small($color) {
    font-size: 1.2rem;
    letter-spacing: 1px;
    font-weight: 600;
    line-height: 1.2;
    color: $color;
    display: inline-block;
    text-transform: uppercase;
    &::after {
        content: "";
        display: inline-block;
        background-color: $color;
        height: 2px;
        width: 30%;
        position: relative;
        top: -0.5rem;
    }
}
