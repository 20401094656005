@media screen and (max-width: 1100px) {
    .news {
        &__wrapper {
            max-width: 95%;
        }
    }
}

@media screen and (max-width: 950px) {
    .single-news {
        width: 48%;
        margin-bottom: 2rem;
    }
}

@media screen and (max-width: 900px) {
    .individual-news {
        &__inner {
           padding: 0 2rem 2rem;
        }
        &__date {
            text-align: center;
            top: 0;
            margin-top: 0.5rem;
            margin-bottom: 1.5rem;
        }
        &__wrapper {
            .main-title {
                margin-bottom: -3rem;
            }
        }
    }
}

@media screen and (max-width: 750px) {
    .individual-news {
        &__img-wrapper {
            margin-bottom: 1.5rem;
        }
    }
}

@media screen and (max-width: 750px) {
    .single-news {
        width: 100%;
    }
}

@media screen and (max-width: 660px) {
    .individual-news {
        &__wrapper {
            .main-title {
                font-size: 4rem;
                top: -2rem;
                margin-bottom: -2rem;
            }
        }
    }
}

