.artists {

    &__link {
        &:hover, &:focus {
            .artists__img {
                transform: translateY(-35%);
            }
            .artists__overlay {
                opacity: 1;
            }
            .artists__text-bg {
                height: 35%;
                padding: 1rem 0;
            }
        }
    }

    &__figure {
        height: 100%;
        max-height: 238px;
        overflow: hidden;
        border: 4px solid $black;
        @include subtleShadow($shadow25);
    }

    &__img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        transition: 0.2s ease-out;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: 0.2s ease-out;
        background-color: $shadow40;
        text-align: center;
        padding-top: 5rem;
    }
    &__link-text {
        font-size: 1rem;
        color: white;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-weight: 500;
    }

    &__text-bg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;
        transition: 0.2s ease-out;
        transition-property: height, padding;
        background-color: $black;
        color: white;
        font-size: 1rem;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__info {
        position: relative;
        left: 1rem;
        text-align: left;
        max-width: calc(100% - 1rem);
    }

    &__name {
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.5px;
        margin-bottom: 0;
    }

    &__disciplines {
        font-size: 0.889rem;
    }

    &__list {
        transform: translate(-2.5%);
    }

    &.home-section {
        background-color: $light-gray;
    }

    &.archive-artists, &.artists-program {
        width: 100%;
        transform: skewY(4deg);
        background: linear-gradient(to right, $pink, $peach);
        .artists__list {
            transform: skewY(-4deg) translate(-2.5%);
        }
    }
}
