.cta {
    color: white;
    @include bg-gradient(right, $pink, $peach);
    transform: skewY(4deg);
    &--inner {
        text-align: center;
        transform: skewY(-4deg);
    }
    &__catch-phrase {
        font-size: 2.5rem;
        font-weight: 500;
    }

    // modifiers
    &--raspberry {
        background: $raspberry;
    }
    &--straight {
        transform: none;
        .cta--inner {
            transform: none;
        }
    }
}
