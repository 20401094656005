.wysiwyg {
    p {
        margin-bottom: 1em;
    }
    br {
        margin-bottom: 0.5em;
    }
    ul, ol {
        list-style-type: disc;
        list-style-position: inside;
    }
    strong {
        font-weight: 500;
    }
    em {
        font-style: italic;
    }
    a {
        text-decoration: underline;
        font-weight: 500;
        transition: 0.2s ease-out;
        &:hover, &:focus {
            color: $raspberry;
        }
    }
}
