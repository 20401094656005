.instagram {
    @include bg-gradient(right, $pink, $peach);
    .sbi {
        &_type_image {
            border: 4px solid white;
            transition: 0.2s ease-out;
            &:hover, &:focus {
                border: 4px solid $black;
            }
        }
    }
}
