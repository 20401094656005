.hidden {
    @include hidden;
}

.svgDefs {
    position: absolute;
    top: 0;
    height: 0;
    width: 0;
}

.hide {
    display: none !important;
}
