.home-title {
    color: $raspberry;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 2rem;
    letter-spacing: 1px;
    width: 100%;
    display: block;
    text-align: center;
    margin-bottom: 3.75rem;
    &--white {
        color: white;
    }
    &.intro__title {
        margin-bottom: 1rem;
    }
}
