@media screen and (max-width: 1100px) {
    .footer {
        &__inner {
            max-width: 90%;
            width: 90%;
        }
        &__nav {
            .footer__list {
                max-width: 100%;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .footer {
        &__inner {
            max-width: 95%;
            width: 95%;
        }
        &__nav {
            .footer__list {
                flex-wrap: wrap;
                justify-content: center;
            }
            .footer__item {
                margin: 0 0.5em 1em;
            }
        }
        &__partenaires {
            width: 50%;
        }
        &__twitter {
            width: 40%;
        }
    }
}

@media screen and (max-width: 660px) {
    .footer {
        padding: 2.5rem 0 1rem;
        &__line {
            flex-wrap: wrap;
            justify-content: flex-start;
            &--1 {
                .footer__column {
                    width: 100%;
                    align-items: center;
                }
            }
        }
        &__partenaires {
            width: 100%;
            text-align: center;
        }
        .partenaires {
            &__list {
                justify-content: center;
            }
        }
        &__twitter {
            margin-top: 2rem;
            width: 100%;
            text-align: center;
            .footer__twitterfeed {
                text-align: left;
            }
        }
        &__social-nav {
            text-align: center;
            margin-bottom: 1.5rem;
        }
        &__title {
            margin-bottom: 0.3rem;
        }
        &__contact {
            margin-bottom: 2rem;
        }
        .contact {
            &__address {
                margin: 0;
            }
            &__infos {
                margin: 0;
                width: 100%;
                text-align: center;
            }
        }
    }
    .newsletter {
        &--footer {
            .mc4wp-form-fields {
                text-align: center;
            }
            .newsletter {
                &__input {
                    width: 60%;
                    padding: 0.8rem;
                }
                &__button {
                    display: inline-block;
                    margin-top: 0;
                    padding: 0.8rem;
                    border-left: none;
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .footer {
        &__inner {
            width: 99%;
            max-width: 99%;
        }
        &__nav {
            display: none;
        }
        &__partenaires {
            display: none;
        }
        &__twitter {
            display: none;
        }
        &__line {
            &--1 {
                margin-bottom: 0;
            }
            &--3 {
                margin-bottom: 0;
            }
        }
    }
}