.instagram {
    display: flex;
    flex-direction: column;
    align-items: center;
    .sbi {
        max-width: $max-width;
        margin: 0 auto;
        [id="sbi_images"] {
            width: 100%;
            margin: 0 auto 2rem -2.5%;
            text-align: center;
        }
        &_type_image {
            width: 22.5% !important;
            min-width: 200px;
            max-height: 225px;
            margin: 0 0 2rem 2.5%;
            overflow: hidden;
        }
        &_photo {
            &:hover, &:focus {
                opacity: 1;
                outline: 1;
            }
        }
    }
}
