.intro__wrapper {
    padding-bottom: 4rem;
    background: transparent url('../images/pattern.svg') center/167px 172px repeat;
}

.intro {
    max-width: 800px;
    margin: 0 auto;
    padding: 1rem 2.5rem;
    text-align: center;
    &--home {
        padding: 2rem 2.5rem;
    }
}
