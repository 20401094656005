$raspberry: #861C48;
$orange: #D83C2B;
$yellow: #FED178; // to darken for text
$dark-gray: #3D2B33;
$light-gray: #F7F7F7;
$black: #000000;

// gradients
$pink: #ED516A;
$peach: #FAB175;
$darkberry: #72214E;
$purple: #522958;

//shadows
$shadow25: rgba(0, 0, 0, 0.25);
$shadow40: rgba(0, 0, 0, 0.4);
$raspberryShadow: rgba(134, 28, 72, 0.25);
$pinkShadow: rgba(237, 81, 106, 0.25);
$orangeShadow: rgba(216, 60, 43, 0.25);
$yellowShadow: rgba(254, 209, 120, 0.5);
